import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { graphql, Link } from "gatsby";
import Metadata from "./metadata"
import "../styles/templates/home.scss"
import ArticleListing from "../components/article-listing"

const Home = ({ data }) => {
  const item = data.kontentItemContentHome.elements

  return (
    <Layout>
      <Metadata pageData={item} siteData={data.site.siteMetadata} />
      <Head />
      <h1 className="hc-heading">{item.heading.value}</h1>
      <div className="hc-wrapper">
        <div className="hc-body-container">
          <section className="rta-body" dangerouslySetInnerHTML={{ __html: item.body_copy.value }} />
        </div>
        <div className="hc-side">
          <h2 className="hc-side-heading">Open for comment</h2>
          <div className="hc-side-content">
            <aside className="rta-side" dangerouslySetInnerHTML={{ __html: item.open_for_comment.value }} />
          </div>
        </div>
        <div className="hc-article-listings">
          <div className="hc-article-column">
            <h2>Latest News</h2>
            <div className="hc-news-listing">
              <ArticleListing data={data.allKontentItemContentNews.edges} />
              <Link to="/news" className="button">View All News</Link>
            </div>
          </div>
          <div className="hc-article-column">
            <h2>Latest Events</h2>
            <div className="hc-events-listing">
              <ArticleListing data={data.allKontentItemContentEvent.edges} landingPage={false} />
              <Link to="/events" className="button">View All Events</Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query homeQuery($slug: String!) {
    kontentItemContentHome(fields: { slug: { eq: $slug } }) {
      elements {
        heading {
          value
        }
        body_copy {
          value
        }
        open_for_comment {
          value
        }
        slug {
          value
        }
        metadata__title {
          value
        }
        metadata__description {
          value
        }
        metadata__image {
          value {
            url
          }
        }
      }
    }
    allKontentItemContentNews(sort: {fields: elements___date___value, order: DESC}, limit: 5) {
      edges {
        node {
          elements {
            metadata__description {
              value
            }
            heading {
              value
            }
            date {
              value(formatString: "DD MMMM YYYY")
            }
            slug {
              value
            }
          }
        }
      }
    }
    allKontentItemContentEvent(sort: {fields: elements___date___value, order: DESC}, limit: 5) {
      edges {
        node {
          elements {
            metadata__description {
              value
            }
            heading {
              value
            }
            date {
              value(formatString: "DD MMMM YYYY")
            }
            slug {
              value
            }
          }
        }
      }
    }
    site {
        siteMetadata {
            lang
            siteUrl
            websiteTitle
            description
        }
    }
  }
`
